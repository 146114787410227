import { jsx } from "react/jsx-runtime";
import { createContext, useState, useEffect, useMemo, useContext } from "react";
function noop() {
}
const context = createContext({
  theme: "light",
  setTheme: noop
});
const { Provider } = context;
function ThemeProvider({
  children,
  theme
}) {
  const [innerTheme, setInnerTheme] = useState(theme);
  useEffect(() => {
    setInnerTheme(theme);
  }, [theme]);
  const value = useMemo(
    () => ({
      theme: innerTheme,
      setTheme: setInnerTheme
    }),
    [innerTheme]
  );
  return /* @__PURE__ */ jsx(Provider, { value, children });
}
function useTheme() {
  const { theme } = useContext(context);
  return theme;
}
function useChildTheme(color, variant) {
  const theme = useTheme();
  return useMemo(() => {
    if (color !== "white" && ["solid", "outline"].includes(variant))
      return color;
    if (color === "white" && variant !== "transparent") return "light";
    return theme;
  }, [theme, color, variant]);
}
export {
  ThemeProvider,
  useChildTheme,
  useTheme
};
